.headers {
  color: #454f63;
  font-family: "Open Sans";
  font-weight: bold;
  font-size: 14px;
  word-wrap: break-word;
  background-color: #f0f1f2;

  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}
