.alert-wrapper.sc-ion-alert-md {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 750px;
  min-width: 750px;
  max-width: 750px;
  height: var(--height);
  min-height: var(--min-height);
  max-height: var(--max-height);
  background: var(--background);
  contain: content;
  opacity: 0;
  z-index: 10;
}
